import React, { useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { useAppDispatch } from "@redux/hooks";
import { Steps, StepProps, pageView } from "@utils";
import { Button, BioTerms, BioPolicy } from "@components";
import {
  setBioTerms,
  setBioPolicy,
  setSelectionComplete,
  setDisclaimerComplete
} from "@redux/actions";

export const Disclosure: React.FC<StepProps> = (props) => {
  const oneClickLegal = Boolean(process.env.NEXT_PUBLIC_ONE_CLICK_LEGAL);
  const [showPolicy, setShowPolicy] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  return (
    <>
      <BioTerms
        oneClickLegal={oneClickLegal}
        onAccept={() => {
          dispatch(setBioTerms(true));
          setShowTerms(false);
        }}
        onHide={() => {
          setShowTerms(false);
        }}
        show={showTerms}
      />
      <BioPolicy
        oneClickLegal={oneClickLegal}
        onAccept={() => {
          dispatch(setBioPolicy(true));
          setShowPolicy(false);
        }}
        onHide={() => {
          setShowPolicy(false);
        }}
        show={showPolicy}
      />
      <Alert variant="secondary">
        <p>
          You are starting an online identity verification process that collects your ID and
          biometric information from your face, to enable you to access a self-guided tour of an
          Irvine Company property. By proceeding and clicking “I Agree and Want to Continue” below,
          you (i) agree to Irvine Company’s{" "}
          <Button bsPrefix="link" onClick={() => setShowTerms(true)}>
            Biometric Terms
          </Button>{" "}
          and (ii) acknowledge you have read Irvine Company’s{" "}
          <Button bsPrefix="link" onClick={() => setShowPolicy(true)}>
            Biometric Privacy Policy
          </Button>
          . Please follow the links to read how the identity verification process works, the
          information collected about you, and how it will be used.
        </p>
        <p>Here is a short description of the verification process.</p>
        <ol>
          <li>
            Use your web camera or mobile device to take photos of the front and back of the ID you
            chose.
          </li>
          <li>
            Frame your face in the mirror on the screen while 6-7 photos are taken of your face. We
            will assess the quality of the images and use your ID information and facial biometric
            information to verify your identity. If your information checks out, you can schedule
            the tour. If the verification fails, if you have any questions, or if you do not want to
            use this process, contact the Leasing Office.
          </li>
        </ol>
        <p>
          I Agree to Irvine Company’s{" "}
          <Button bsPrefix="link" onClick={() => setShowTerms(true)}>
            Biometric Terms
          </Button>{" "}
          and acknowledge that I have read Irvine Company’s{" "}
          <Button bsPrefix="link" onClick={() => setShowPolicy(true)}>
            Biometric Privacy Policy
          </Button>
          , and Want to Continue.
        </p>
      </Alert>
      <Row noGutters>
        <Col sm="auto" className="pt-4">
          <Button
            className="mr-3"
            data-testid="backButton"
            onClick={() => {
              dispatch(setSelectionComplete(false));
              pageView(`/#${Steps.states}`);
              props.goToStep(Steps.states);
            }}
            size="sm">
            <FontAwesomeIcon icon={faChevronLeft} style={{ width: "0.75rem" }} /> Go Back
          </Button>
        </Col>
        {!oneClickLegal && (
          <>
            <Col sm="auto" className="pt-4">
              <Button
                onClick={() => setShowTerms(true)}
                className={props.state.acceptedBioTerms ? "mr-3 complete" : "mr-3"}
                size="sm">
                {props.state.acceptedBioTerms ? (
                  <>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ width: "0.75rem", verticalAlign: "baseline" }}
                    />{" "}
                    Biometric Terms
                  </>
                ) : (
                  "Read Biometric Terms"
                )}
              </Button>
            </Col>
            <Col sm="auto" className="pt-4">
              <Button
                onClick={() => setShowPolicy(true)}
                className={props.state.acceptedBioPolicy ? "mr-3 complete" : "mr-3"}
                size="sm">
                {props.state.acceptedBioPolicy ? (
                  <>
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ width: "0.75rem", verticalAlign: "baseline" }}
                    />{" "}
                    Biometric Privacy Policy
                  </>
                ) : (
                  "Read Biometric Privacy Policy"
                )}
              </Button>
            </Col>
          </>
        )}
        <Col sm="auto" className="pt-4">
          <Button
            data-testid="agreeButton"
            onClick={() => {
              dispatch(setDisclaimerComplete(true));
              pageView(`/#${Steps.tips}`);
              props.goToStep(Steps.tips);
            }}
            size="sm"
            disabled={
              (!oneClickLegal && !props.state.acceptedBioPolicy && !props.state.acceptedBioTerms) ||
              !props.state.issuingAgency ||
              !props.state.movingFrom
            }>
            I Agree and Want to Continue
          </Button>
        </Col>
      </Row>
    </>
  );
};
