import axios from "axios";
import React, { useState } from "react";
import { UserProfile } from "@auth0/nextjs-auth0";
import { Alert, Card, Form, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { Button } from "@components";
import { useTypedDispatch } from "@redux/hooks";
import { setEmailAsync, setEmailIsValidAsync } from "@redux/actions";
import { Steps, StepProps, isValidCode, getErrorMessage, pageView } from "@utils";

export const Code: React.FC<StepProps> = (props) => {
  const [alertText, setAlertText] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const dispatch = useTypedDispatch();

  return (
    <Card className="border-none">
      <Card.Header className="h3">Check your email</Card.Header>
      <Card.Body>
        <Card.Text>
          Next, we’ll need the code we sent to <i>{props.state.email}</i>.
        </Card.Text>
        <Form.Control
          required
          id="code"
          type="text"
          value={code}
          maxLength={6}
          inputMode="numeric"
          data-testid="codeText"
          disabled={!props.isActive}
          isValid={isValidCode(code)}
          placeholder="Enter your code"
          onChange={(event) => {
            setAlertText("");
            setCode(event.target.value);
          }}
        />
        <Form.Text className="text-muted">
          The subject of the email will be “Your Tour, Endless Possibilities”
        </Form.Text>
        <Alert show={alertText !== ""} variant="danger" className="mt-4 mb-0">
          {alertText}
        </Alert>
        <Row noGutters>
          <Col sm="auto" className="pt-4">
            <Button
              data-testid="backButton"
              className="mr-3"
              onClick={() => {
                dispatch(setEmailAsync(undefined)).then(() => {
                  pageView(`/#${Steps.email}`);
                  props.goToStep(Steps.email);
                  setAlertText("");
                });
              }}
              size="sm">
              <FontAwesomeIcon icon={faChevronLeft} style={{ width: "0.75rem" }} /> Go Back
            </Button>
          </Col>
          <Col sm="auto" className="pt-4">
            <Button
              size="sm"
              data-testid="verifyButton"
              disabled={!isValidCode(code)}
              onClick={() => {
                axios
                  .post<UserProfile>("/api/email/verify", {
                    email: props.state.email,
                    code
                  })
                  .then(
                    (result) => {
                      if (result.status === 200 && result.data.email_verified) {
                        dispatch(setEmailIsValidAsync(result.data.sub)).then(() => {
                          pageView(`/#${Steps.tours}`);
                          props.goToStep(Steps.tours);
                          setAlertText("");
                        });
                      } else {
                        setAlertText(result.statusText);
                      }
                    },
                    (error) => {
                      setAlertText(getErrorMessage(error).message);
                    }
                  );
              }}>
              Verify
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
