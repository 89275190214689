import axios from "axios";
import React, { useState } from "react";
import { UserProfile } from "@auth0/nextjs-auth0";
import { Alert, Card, Form } from "react-bootstrap";

import { Button } from "@components";
import { useTypedDispatch } from "@redux/hooks";
import { setEmailAsync } from "@redux/actions";
import { Steps, StepProps, isValidEmail, getErrorMessage, pageView } from "@utils";

export const Email: React.FC<StepProps> = (props) => {
  const [emailToCheck, setEmailToCheck] = useState<string>(props.state.email);
  const [alertText, setAlertText] = useState<string>("");
  const dispatch = useTypedDispatch();

  return (
    <Card className="border-none">
      <Card.Header className="h3">Let’s get started</Card.Header>
      <Card.Body>
        <Card.Text>First, we’ll need your email address.</Card.Text>
        <Form.Control
          required
          id="email"
          type="email"
          inputMode="email"
          value={emailToCheck}
          disabled={!props.isActive}
          placeholder="Enter your email"
          isValid={isValidEmail(emailToCheck)}
          onChange={(event) => {
            setAlertText("");
            setEmailToCheck(event.target.value);
          }}
        />
        <Form.Text className="text-muted pb-4">
          Please provide the email address that you used when registering for your tour.
        </Form.Text>
        <Alert show={alertText !== ""} variant="danger" className="mb-4">
          {alertText}
        </Alert>
        <Button
          size="sm"
          disabled={!isValidEmail(emailToCheck)}
          onClick={() => {
            axios.post<UserProfile>("/api/email/send", { email: emailToCheck }).then(
              (result) => {
                if (result.status === 200) {
                  dispatch(setEmailAsync(emailToCheck)).then(() => {
                    pageView(`/#${Steps.code}`);
                    props.goToStep(Steps.code);
                    setAlertText("");
                  });
                } else {
                  setAlertText(result.statusText);
                }
              },
              (error) => {
                setAlertText(getErrorMessage(error).message);
              }
            );
          }}>
          Send Code
        </Button>
      </Card.Body>
    </Card>
  );
};
