import React, { RefObject, useState } from "react";
import { Modal } from "react-bootstrap";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import { Button } from "@components";
import { ModalProps } from "@utils";

export const BioTerms: React.FC<ModalProps> = (props) => {
  const [unread, setUnread] = useState<boolean>(true);
  const scrollRef: RefObject<HTMLDivElement> = useBottomScrollListener(() => {
    setUnread(false);
  });

  return (
    <Modal
      centered
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-fill"
      keyboard={props.oneClickLegal}
      backdrop={props.oneClickLegal ? true : "static"}>
      <Modal.Header style={{ fontWeight: 700 }} closeButton={props.oneClickLegal}>
        Irvine Company Biometric Terms
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", maxHeight: 720 }} ref={scrollRef}>
        <p>
          Effective Date: May 10, 2021
          <br />
          Last Updated: May 6, 2022
        </p>
        <p>
          These Biometric Terms apply only to the identity verification process using facial
          biometric information offered by The Irvine Company LLC and its subsidiaries and
          affiliates (“Irvine” or “we”).
        </p>
        <p>
          PLEASE BE AWARE THAT SECTION 9 OF THESE BIOMETRIC TERMS, BELOW, CONTAINS PROVISIONS
          GOVERNING HOW DISPUTES THAT YOU AND WE HAVE AGAINST EACH OTHER ARE RESOLVED. IN
          PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS,
          REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU
          OPT OUT OF THE ARBITRATION TERMS: (1) YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR
          CLAIMS AND SEEK RELIEF AGAINST IRVINE ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR
          RIGHT TO PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY
          TRIAL.
        </p>
        <p>
          ANY DISPUTE OR CLAIM BETWEEN YOU AND IRVINE WILL BE GOVERNED AND INTERPRETED BY AND UNDER
          THE LAWS OF THE STATE OF CALIFORNIA CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT
          GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY OTHER
          JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF
          GOODS IS EXPRESSLY EXCLUDED FROM THIS AGREEMENT.
        </p>
        <p>
          <b>1) ACCEPTANCE OF BIOMETRIC TERMS</b>
        </p>
        <p>
          Please carefully read these Biometric Terms before using the online identity verification
          process using facial biometric information (the “Process”). By using the Process, you
          acknowledge that you have read, understood and agree to be bound by these Biometric Terms
          which form an agreement that is effective as if you had signed it, and that you have read
          and understood the Biometric Privacy Policy. If at any time you do not agree to these
          Biometric Terms or the disclosures in the Biometric Privacy Policy, please do not access
          or use the Process, or terminate your use of the Process.
        </p>
        <p>
          YOUR USE OF THE PROCESS IS SUBJECT TO THESE BIOMETRIC TERMS, IRVINE’S BIOMETRIC PRIVACY
          POLICY AND ALL APPLICABLE LAWS AND REGULATIONS. IF YOU DO NOT AGREE TO THESE BIOMETRIC
          TERMS, YOUR PERMISSION TO USE THE PROCESS IS AUTOMATICALLY AND IMMEDIATELY REVOKED.
        </p>
        <p>
          <b>2) AGE RESTRICTIONS</b>
        </p>
        <p>
          The Process is directed to persons 18 years of age or older. Irvine does not knowingly use
          of the Process for children under age 18.
        </p>
        <p>
          <b>3) PERMITTED USE OF PROCESS</b>
        </p>
        <p>
          You may only use the Process when initiated by Irvine in response to your request for
          services, such as a tour of an Irvine property (whether in-person or virtual). Your use of
          the Process signifies your confirmation that all information you provide through the
          Process is current, active, true and complete to the best of your knowledge, and may be
          used for fraud prevention purposes.
        </p>
        <p>
          The Process and its screens (collectively, the “Content”) is the sole and exclusive
          property of Irvine or its licensors. You agree not to reproduce, duplicate, modify, copy,
          sell, resell, modify, alter or exploit for any commercial purpose, any portion of the
          Content. You acknowledge and agree that, as between Irvine and you, all right, title, and
          interest in and to the Process and Content, including without limitation any intellectual
          property rights, are owned exclusively by Irvine or its licensors, are valid and
          enforceable, and are protected by United States intellectual property laws and other
          applicable laws.
        </p>
        <p>
          <b>4) YOUR INDEMNITY OF IRVINE</b>
        </p>
        <p>
          YOU AGREE TO INDEMNIFY, DEFEND AND HOLD IRVINE, ITS OFFICERS, DIRECTORS, EMPLOYEES,
          AGENTS, REPRESENTATIVES, SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS AND OTHERS
          ACTING IN CONCERT WITH IT, HARMLESS FROM ANY LOSS, LIABILITY, CLAIM OR DEMAND, INCLUDING
          WITHOUT LIMITATION INJURY TO PERSON OR PROPERTY OR DEATH, AND REASONABLE ATTORNEYS’ FEES,
          MADE BY YOU OR ON YOUR BEHALF OR BY ANY THIRD PARTY DUE TO OR ARISING OUT OF (A) YOUR
          CONNECTION TO OR USE OF THE PROCESS; OR (B) YOUR VIOLATION OF THESE BIOMETRIC TERMS, ANY
          APPLICABLE LAWS, OR THE RIGHTS OF IRVINE OR ANY THIRD PARTY. IRVINE RESERVES THE RIGHT TO
          ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO YOUR INDEMNIFICATION, AT
          YOUR EXPENSE, AND IN SUCH CASE YOU WILL COOPERATE WITH IRVINE’S DEFENSE OF SUCH CLAIM.
        </p>
        <p>
          <b>5) SUSPENSION AND TERMINATION RIGHTS</b>
        </p>
        <p>
          Irvine reserves the right, at its sole discretion, immediately and without notice, to
          suspend or terminate your access to or use of the Process or any part thereof for any
          reason, including without limitation any breach by you of these Biometric Terms. You agree
          that Irvine shall not be liable to you or any third party for any such suspension or
          termination.
        </p>
        <p>
          <b>6) DISCLAIMER</b>
        </p>
        <p>
          THE PROCESS USES YOUR GOVERNMENT-ISSUED ID AND PHOTOS TO ASSESS YOUR IDENTITY IN AN
          AUTOMATED MANNER. NO AUTOMATED PROCESS IS ALWAYS CORRECT. IT IS POSSIBLE THAT THE PROCESS
          WILL FAIL OR REJECT INFORMATION YOU PROVIDE OR YIELD INCORRECT RESULTS. IF YOU DO NOT
          AGREE WITH THE PROCESS OR THE RESULT, YOUR ONLY RECOURSE IS TO CONTACT THE LEASING OFFICE
          TO REQUEST A DIFFERENT METHOD OF VERIFICATION, OR TO WITHDRAW YOUR REQUEST FOR
          VERIFICATION. IRVINE’S DECISIONS ON VERIFICATION ARE IN FINAL, ITS SOLE AND ABSOLUTE
          DISCRETION.
        </p>
        <p>
          THE PROCESS AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
          WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY
          LAW, IRVINE AND ITS LICENSORS, SUPPLIERS AND RELATED PARTIES DISCLAIM ALL REPRESENTATIONS
          AND WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PROCESS, CONTENTS AND RESULTS,
          INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
          PURPOSE, TITLE, ACCURACY, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS OR OTHER HARMFUL
          CODE. YOUR USE OF THE PROCESS IS ENTIRELY AT YOUR OWN RISK.
        </p>
        <p>
          <b>7) LIMITATION ON LIABILITY</b>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, IRVINE AND ITS RELATED PARTIES DISCLAIM ALL
          LIABILITY, WHETHER BASED IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE),
          STRICT LIABILITY OR ANY OTHER THEORY ARISING OUT OF OR IN CONNECTION WITH THE PROCESS,
          CONTENTS OR RESULTS, AND YOUR USE OR INABILITY TO USE THEM. IN NO EVENT SHALL IRVINE OR
          ANY OF ITS LICENSORS, SUPPLIERS OR RELATED PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT,
          SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, EVEN IF THESE ENTITIES
          HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS SHALL
          APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OR THE EXISTENCE OF ANY LIMITED
          REMEDY. WITHOUT LIMITING THE FOREGOING, THE MAXIMUM AGGREGATE LIABILITY OF IRVINE ARISING
          OUT OF OR IN CONNECTION WITH THESE BIOMETRIC TERMS OR THE PROCESS, OR THE CONTENT,
          INFORMATION, MATERIALS, PRODUCTS OR SERVICES ON OR THROUGH THE PROCESS SHALL NOT EXCEED
          FIFTY DOLLARS (U.S.).
        </p>
        <p>
          Exclusions and Limitations: Because some jurisdictions do not allow limitations on how
          long an implied warranty lasts, or the exclusion or limitation of liability for
          consequential or incidental damages, the above limitations may not apply to you. This
          Limitation of Liability shall be to the maximum extent permitted by applicable law.
        </p>
        <p>
          <b>8) NOTICE REQUIRED BY CALIFORNIA LAW</b>
        </p>
        <p>
          Pursuant to California Civil Code Section 1789.3, users are entitled to the following
          specific consumer rights notice:
        </p>
        <p>
          The name, address and telephone number of the provider of the Process is Irvine Company
          LLC, 550 Newport Center Drive, Newport Beach, CA 92660, U.S.A., telephone 949-720-2000.
          Complaints regarding the Process or Content or requests to receive further information
          regarding use of this Process may be sent to the above address or to{" "}
          <a href="mailto:information@irvinecompany.com" className="link">
            information@irvinecompany.com
          </a>
        </p>
        <p>
          The Complaint Assistance Unit of the Division of Consumer Services of the California
          Department of Consumer Affairs may be contacted in writing at 1625 North Market Boulevard,
          Suite S202, Sacramento, CA 95834 or by telephone at (916) 574-7950 or (800) 952-5210.
        </p>
        <p>
          <b>9) DISPUTE RESOLUTION</b>
        </p>
        <p>
          Please read the following terms relating to the parties’ arbitration rights and
          obligations stated herein (the “Arbitration Terms”) carefully. It requires you to
          arbitrate disputes with Irvine and limits the manner in which you can seek relief from us.
        </p>
        <p className="ml-4">
          <b>9.1 Applicability of Arbitration Terms.</b>You and Irvine agree that in the event of
          any dispute or claim between you and Irvine relating to: (a) the Process, Content or
          results; or (b) any matters relating to privacy, collection or use of personal
          information, data breach, or any alleged violation of federal or state privacy or data
          breach statutes, whether based in contract, tort, statute, fraud, misrepresentation, or
          any other legal theory arising from these Biometric Terms or the Process or Content
          (individually, a “Claim” and collectively, “Claims”), such Claims will be resolved by
          binding arbitration, rather than in court, except that (1) you may assert Claims or seek
          relief in small claims court if your Claims qualify; and (2) you or Irvine may seek
          equitable relief in court for infringement or other misuse of intellectual property rights
          (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).
          The Arbitration Terms shall apply, without limitation, to all Claims that arose or were
          asserted either before or after the effective date of these Biometric Terms.
        </p>
        <p className="ml-4">
          <b>9.2 Arbitration Rules and Forum.</b>The Federal Arbitration Act governs the
          interpretation and enforcement of the Arbitration Terms set forth herein. To begin an
          arbitration proceeding, you must send a letter requesting arbitration and describing your
          Claim to our registered agent: CSC &ndash; Lawyers Incorporating Service, 2710 Gateway
          Oaks Drive, Suite 150N, Sacramento, CA 95833. Disputes involving Claims seeking relief in
          an amount less than $250,000, not inclusive of attorneys’ fees and interest, shall be
          subject to JAMS’s most current version of the Streamlined Arbitration Rules and
          procedures; all other disputes shall be subject to JAMS’s most current version of the
          Comprehensive Arbitration Rules and Procedures. JAMS arbitration rules are available at
          http://www.jamsadr.com/. If JAMS is not available to arbitrate, the parties will select an
          alternative arbitral forum. Irvine shall pay all costs and expenses unique to arbitration,
          including without limitation the JAMS and arbitrator’s fees, except that if you are the
          party initiating the claim, you must contribute a sum equal to the filing fee in the court
          of general jurisdiction in Orange County, California. If you are represented by legal
          counsel, you must pay in the first instance your own attorney’s fees. You may choose to
          have the arbitration conducted: (1) by telephone, (2) based on written submissions, or (3)
          in person at Orange County, California, USA or at another mutually agreed location.
        </p>
        <p className="ml-4">
          <b>9.3 Authority of Arbitrator.</b>All arbitrations shall be conducted before a single
          neutral arbitrator, who shall be licensed to practice law in the jurisdiction where the
          arbitration shall occur. The arbitrator shall have exclusive authority to (a) determine
          the scope and enforceability of the Arbitration Terms set forth herein and (b) resolve any
          dispute related to the interpretation, applicability, enforceability or formation of the
          Arbitration Terms including, but not limited to, any assertion that all or any part of the
          Arbitration Terms is void or voidable. The arbitration will decide the rights and
          liabilities, if any, of you and Irvine. The arbitration proceeding will not be
          consolidated with any other matters or joined with any other cases or parties. The
          arbitrator shall have the authority to grant motions dispositive of all or part of any
          claim. The arbitrator shall have the authority to award monetary damages and to grant any
          non-monetary remedy or relief available to an individual under applicable law, the
          arbitral forum’s rules, and these Biometric Terms (including the Arbitration Terms stated
          herein). The arbitrator shall issue a written award and statement of decision describing
          the essential findings and conclusions on which the award is based, including the
          calculation of any damages awarded. The arbitrator has the same authority to award relief
          on an individual basis that a judge in a court of law would have. The award of the
          arbitrator is final and binding upon you and us. The decision rendered by the arbitrator
          may be entered as a judgment in any court of competent jurisdiction.
        </p>
        <p className="ml-4">
          <b>9.4 Waiver of Jury Trial.</b>THE PARTIES UNDERSTAND THAT, ABSENT THIS MANDATORY
          ARBITRATION PROVISION, THEY WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
          THEY FURTHER UNDERSTAND THAT, IN SOME INSTANCES, THE COSTS OF ARBITRATION COULD EXCEED THE
          COSTS OF LITIGATION AND THE RIGHT TO DISCOVERY MAY BE MORE LIMITED IN ARBITRATION THAN IN
          COURT. NOTWITHSTANDING THE FOREGOING, YOU AND IRVINE HEREBY WAIVE ANY CONSTITUTIONAL AND
          STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and
          Irvine are instead electing that all Claims shall be resolved by arbitration under this
          Biometric Terms, except as specified in Section 9.1 above.
        </p>
        <p className="ml-4">
          <b>9.5 Waiver of Class or Other Non-Individualized Relief.</b>ALL CLAIMS WITHIN THE SCOPE
          OF THE ARBITRATION TERMS MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR
          COLLECTIVE BASIS. ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE
          CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
          USER. If a decision is issued or ruling made stating that applicable law precludes
          enforcement of any of this subsection’s limitations on bringing Claims on a class action
          basis, then such Claim must be severed from the arbitration and brought in the State or
          Federal Courts located in the State of California. All other Claims shall be arbitrated.
          The parties expressly agree not to arbitrate any Claim on a class or collective basis.
        </p>
        <p className="ml-4">
          <b>9.6 30-Day Right to Opt Out.</b>You have the right to opt out of the provisions of the
          Arbitration Terms by sending written notice of your decision to opt out to:
          arbitration-opt-out@irvinecompany.com within 30 days after first becoming subject to the
          Arbitration Terms. Your notice must include your name and address, your username (if any)
          and the email address you used to set up your Irvine account (if you have one), and with
          the subject line, “MANDATORY ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.” If you opt out
          of the Arbitration Terms, all other parts of these Biometric Terms will continue to apply
          to you. Opting out of the Arbitration Terms has no effect on any other arbitration
          agreements that you may currently have, or may enter in the future, with us.
        </p>
        <p className="ml-4">
          <b>9.7 Severability.</b>Except as provided in subsection 9.5, if any part or parts of the
          Arbitration Terms are found under the law to be invalid or unenforceable, then such
          specific part or parts shall be of no force and effect and shall be severed and the
          remainder of the Arbitration Terms shall continue in full force and effect.
        </p>
        <p>
          <b>10) GEOGRAPHIC LOCATION</b>
        </p>
        <p>
          The Process is controlled within the United States of America and directed to individuals
          residing in the United States. Those who choose to access the Process from locations
          outside the United States do so on their own initiative, and are responsible for
          compliance with local laws if and to the extent local laws are applicable. Irvine does not
          represent that the Process is appropriate for use outside the United States. Irvine
          reserves the right to limit the availability of the Process to any person, geographic area
          or jurisdiction at any time in its sole discretion.
        </p>
        <p>
          <b>11) FORCE MAJEURE</b>
        </p>
        <p>
          Irvine shall not be liable for any delay or failure to perform resulting from causes
          outside its reasonable control or unforeseen circumstances including but not limited to
          acts of nature or God, fire, flood, earthquake, epidemic, pandemic, accidents, strikes,
          war (including without limitation cyberattacks or cyber-related incidents publicly
          attributed to state or quasi-state actors by a government or private entity), terrorism,
          governmental act, failure of or interruption in common carriers (including without
          limitation Internet service providers and web hosting providers) or utilities, or
          shortages of transportation facilities, fuel, energy, labor or materials.
        </p>
        <p>
          <b>12) MISCELLANEOUS</b>
        </p>
        <p>
          These Biometric Terms and the Biometric Privacy Policy set forth the entire agreement and
          understanding between you and Irvine with respect to the subject matter hereof. If any
          provision of the Biometric Terms or Biometric Privacy Policy is found by a court of
          competent jurisdiction to be invalid, the parties nevertheless agree that the court should
          endeavor to give effect to the parties’ intentions as reflected in the provision, and the
          other provisions of the Biometric Terms or Biometric Privacy Policy shall remain in full
          force and effect. Headings are for reference only and in no way define, limit, construe or
          describe the scope or extent of such section. Irvine’s failure to act with respect to any
          failure by you or others to comply with the Biometric Terms or Biometric Privacy Policy
          does not waive its right to act with respect to subsequent or similar failures. You may
          not assign or transfer the Biometric Terms or Biometric Privacy Policy or your rights or
          obligations under the Biometric Terms or Biometric Privacy Policy without the prior
          written consent of Irvine, and any assignment or transfer in violation of this provision
          shall be null and void. There are no third party beneficiaries to the Biometric Terms or
          Biometric Privacy Policy.
        </p>
        <p>
          <b>13) QUESTIONS?</b>
        </p>
        <p>
          Please direct any questions you may have about these Biometric Terms, the Process or
          Content, any technical questions or problems about the Process, or any results, to the
          Leasing Office.
        </p>
      </Modal.Body>
      {!props.oneClickLegal && (
        <Modal.Footer>
          <Button onClick={props.onAccept} disabled={unread} active={!unread} size="sm">
            I Agree and Continue
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
