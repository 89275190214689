import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { Button } from "@components";
import { useTypedDispatch } from "@redux/hooks";
import { Steps, StepProps, pageView } from "@utils";
import { setHasTours, setEmailHasNoToursAsync } from "@redux/actions";

export const Tours: React.FC<StepProps> = (props) => {
  const dispatch = useTypedDispatch();

  return props.tourDataValidating ? (
    <Spinner animation="border" className="spinner" />
  ) : props.tourData?.hasTours ? (
    <Card className="border-none" data-testid="hasTours">
      <Card.Header className="h3">You’ve got tours!</Card.Header>
      <Card.Body>
        <Card.Text>
          Let’s get you verified for your upcoming tour at{" "}
          <b>{props.tourData.nextScheduledTour.communityName}</b>!
        </Card.Text>
        <Button
          data-testid="startButton"
          onClick={() => {
            dispatch(setHasTours(true));
            pageView(`/#${Steps.states}`);
            props.goToStep(Steps.states);
          }}
          size="sm">
          Start
        </Button>
        <Card.Subtitle className="text-muted mt-3">
          In order to keep our communities and residents secure, we ask all guests to verify their
          identity before arriving to tour.
        </Card.Subtitle>
      </Card.Body>
    </Card>
  ) : (
    <Card className="border-none" data-testid="noTours">
      <Card.Header className="h3">You have no tours scheduled!</Card.Header>
      <Card.Body>
        <Card.Text>
          I’m sorry, no tours have been scheduled for{" "}
          {props.state.email ? <i>{props.state.email}</i> : "you"}.
        </Card.Text>
        <Button
          onClick={() => {
            dispatch(setEmailHasNoToursAsync()).then(() => {
              pageView(`/#${Steps.email}`);
              props.goToStep(Steps.email);
            });
          }}
          size="sm">
          <FontAwesomeIcon icon={faChevronLeft} style={{ width: "0.75rem" }} /> Go Back
        </Button>
      </Card.Body>
    </Card>
  );
};
