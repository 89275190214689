import React from "react";
import Select from "react-select";
import { Card } from "react-bootstrap";

import { Button } from "@components";
import { useAppDispatch } from "@redux/hooks";
import { Steps, StepProps, pageView } from "@utils";
import { setMovingFrom, setIssuingAgency, setSelectionComplete } from "@redux/actions";

import { states } from "@data";

export const States: React.FC<StepProps> = (props) => {
  const dispatch = useAppDispatch();

  return (
    <Card className="border-none">
      <Card.Header className="h3">Tell us a little more about yourself</Card.Header>
      <Card.Body>
        <Card.Title>Which state issued your ID?</Card.Title>
        <Select
          className="dropdown"
          classNamePrefix="dropdown"
          name="issuingAgency"
          options={states}
          defaultValue={
            props.state.issuingAgency
              ? states.find((item) => item.value === props.state.issuingAgency)
              : undefined
          }
          onChange={(selected) => dispatch(setIssuingAgency(selected.value))}
        />
        <Card.Text className="text-muted pt-1">
          Please select “-Other-” if you’ll be using a Passport
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Title>What is your state of residence?</Card.Title>
        <Select
          className="dropdown2"
          classNamePrefix="dropdown"
          name="movingFrom"
          options={states}
          defaultValue={
            props.state.movingFrom
              ? states.find((item) => item.value === props.state.movingFrom)
              : undefined
          }
          onChange={(selected) => dispatch(setMovingFrom(selected.value))}
        />
        <Card.Text className="text-muted pt-1">
          Please select “-Other-” if you’re outside the U.S.
        </Card.Text>
      </Card.Body>
      <Card.Body>
        <Button
          size="sm"
          data-testid="beginButton"
          onClick={() => {
            dispatch(setSelectionComplete(true));
            pageView(`/#${Steps.disclosure}`);
            props.goToStep(Steps.disclosure);
          }}
          disabled={!props.state.issuingAgency || !props.state.movingFrom}>
          Begin
        </Button>
      </Card.Body>
    </Card>
  );
};
