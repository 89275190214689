import React from "react";
import moment from "moment-timezone";
import { Card, Spinner } from "react-bootstrap";

import { Button } from "@components/button";
import { LOCAL_TZ } from "@utils/constants";
import { useTypedDispatch } from "@redux/hooks";
import { setLastRetryAttemptAsync } from "@redux/actions";
import { StepProps, contactInfo, getJumioUrl, pageEvent } from "@utils";

export const Done: React.FC<StepProps> = (props) => {
  const dispatch = useTypedDispatch();

  return props.tourDataValidating ? (
    <Spinner animation="border" className="spinner" />
  ) : (
    <Card className="border-none">
      <Card.Header className="h3">
        {`${
          props.tourData?.idVerifyStatus === "Denied" ||
          props.tourData?.idVerifyStatus === "Not Readable"
            ? "I’m sorry"
            : "Thank you"
        }${props.tourData?.user?.firstName ? `, ${props.tourData.user.firstName}!` : "!"}`}
      </Card.Header>
      {props.tourData?.idVerifyStatus === "Incomplete" && (
        <Card.Body data-testid="incomplete">
          <Card.Title>Verifying Identity</Card.Title>
          <Card.Text className="spinner-text">
            <Spinner animation="border" className="spinner" />
            This may take a few minutes...
          </Card.Text>
        </Card.Body>
      )}
      {props.tourData?.idVerifyStatus === "Not Readable" && (
        <Card.Body data-testid="notReadable">
          <Card.Title>Identity Verification Unsuccessful</Card.Title>
          <Card.Text>
            Please click below to Try Again, or contact the leasing office for assistance.
          </Card.Text>
          {props.tourData?.nextScheduledTour && (
            <Card.Text className="ml-3">{contactInfo(props.tourData.nextScheduledTour)}</Card.Text>
          )}
          <Button
            size="sm"
            onClick={() => {
              if (
                props.state.issuingAgency &&
                props.state.movingFrom &&
                props.state.emailSub &&
                props.state.email
              ) {
                dispatch(setLastRetryAttemptAsync()).then(() => {
                  pageEvent({
                    action: "launch_jumio",
                    category: "engagement",
                    label:
                      props.state.issuingAgency === "IL" || props.state.movingFrom === "IL"
                        ? "IL"
                        : props.state.movingFrom
                  });
                  getJumioUrl(
                    props.state.issuingAgency,
                    props.state.movingFrom,
                    props.state.email,
                    props.state.emailSub
                  ).then(
                    (url) => {
                      location.assign(url);
                    },
                    (error) => {
                      window.alert(error);
                    }
                  );
                });
              } else {
                location.assign("/");
              }
            }}>
            Try Again
          </Button>
        </Card.Body>
      )}
      {props.tourData?.idVerifyStatus === "Denied" && (
        <Card.Body data-testid="denied">
          <Card.Title>Identity Verification Unsuccessful</Card.Title>
          <Card.Text>Please contact the leasing office for assistance.</Card.Text>
          {props.tourData?.nextScheduledTour && (
            <Card.Text className="ml-3">{contactInfo(props.tourData.nextScheduledTour)}</Card.Text>
          )}
        </Card.Body>
      )}
      {props.tourData?.idVerifyStatus === "Valid" && (
        <Card.Body data-testid="valid">
          <Card.Title>Identity Verification Successful!</Card.Title>
          <Card.Text>
            Please contact the leasing office for details on how to begin your tour.
          </Card.Text>
          {props.tourData?.nextScheduledTour && (
            <Card.Text className="ml-3">
              {contactInfo(props.tourData.nextScheduledTour, true)}
            </Card.Text>
          )}
          {props.tourData?.idVerifyDateTime && (
            <Card.Subtitle className="text-muted">
              Your verification is valid until{" "}
              {moment.tz(props.tourData.idVerifyDateTime, LOCAL_TZ).add(30, "days").format("LL")}
            </Card.Subtitle>
          )}
        </Card.Body>
      )}
    </Card>
  );
};
