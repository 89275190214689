import React, { useState } from "react";
import { Alert, Card } from "react-bootstrap";

import { Button } from "@components/button";
import { useTypedDispatch } from "@redux/hooks";
import { setLastRetryAttemptAsync } from "@redux/actions";
import { getJumioUrl, StepProps, pageEvent } from "@utils";

export const Tips: React.FC<StepProps> = (props) => {
  const dispatch = useTypedDispatch();
  const [alertText, setAlertText] = useState<string>("");
  const idOnly = props.state.issuingAgency === "IL" || props.state.movingFrom === "IL";

  return (
    <Card className="border-none">
      <Card.Header className="h3">Before you begin</Card.Header>
      <Card.Body>
        <Card.Title>Here are some tips to ensure the best results.</Card.Title>
        <ul>
          <li>Make sure that you have a strong Data or WiFi connection.</li>
          <li>Have your Driver’s License or Passport out and ready to scan.</li>
          <li>
            Ensure that you have proper lighting so that the camera can clearly capture your{" "}
            {idOnly ? "document" : "document and face"}.
          </li>
          {!idOnly && (
            <li>
              Remove any hats, glasses, or other items that might obscure your face or make you
              appear different than your ID photo.
            </li>
          )}
        </ul>
        <Button
          onClick={() => {
            dispatch(setLastRetryAttemptAsync()).then(() => {
              if (
                props.state.issuingAgency &&
                props.state.movingFrom &&
                props.state.emailSub &&
                props.state.email
              ) {
                pageEvent({
                  action: "launch_jumio",
                  category: "engagement",
                  label:
                    props.state.issuingAgency === "IL" || props.state.movingFrom === "IL"
                      ? "IL"
                      : props.state.movingFrom
                });
                getJumioUrl(
                  props.state.issuingAgency,
                  props.state.movingFrom,
                  props.state.email,
                  props.state.emailSub
                ).then(
                  (url) => {
                    location.assign(url);
                  },
                  (error) => {
                    setAlertText(error);
                  }
                );
              } else {
                location.assign("/");
              }
            });
          }}
          size="sm">
          Start Verification
        </Button>
        <Card.Subtitle className="text-muted mt-4">
          The process can take about 5 minutes to complete.
        </Card.Subtitle>
        <Alert show={alertText !== ""} variant="danger" className="mt-4">
          {alertText}
        </Alert>
      </Card.Body>
    </Card>
  );
};
