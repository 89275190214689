import React from "react";
import getConfig from "next/config";
import { Container, Row, Col } from "react-bootstrap";

import { Button, Logo } from "@components";

const { publicRuntimeConfig } = getConfig();
const { IS_PROD } = publicRuntimeConfig;

type HeaderProps = {
  showLogout: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
  return (
    <Container fluid style={{ backgroundColor: "#fff" }}>
      <Row>
        <Col className="text-left p-4">
          <Logo />
        </Col>
        {!IS_PROD && props.showLogout && (
          <Col className="text-right p-4">
            <Button variant="light" size="sm" href="/logout">
              Logout
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};
