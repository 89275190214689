/* eslint-disable @next/next/no-img-element */
import React from "react";
import Link from "next/link";

export const Logo: React.FC = () => {
  return (
    <Link href="/">
      <a style={{ padding: 0 }}>
        <img
          src="https://dynamicmedia.irvinecompany.com/is/image/content/dam/apartments/3-readytopublish/graphics/corporatelogos/icac/IC-Apartments-2012-rb.eps.eps?&wid=300&iccEmbed=1&icc=AdobeRGB&fmt=png"
          alt="Irvine Company Apartments"
          width="300"
          height="52"
        />
      </a>
    </Link>
  );
};
