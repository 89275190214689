import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

import type { RootState, AppDispatch } from "./store";

export type TypedDispatch = ThunkDispatch<RootState, never, AnyAction>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTypedDispatch = () => useDispatch<TypedDispatch>();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
