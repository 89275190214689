import React from "react";
import StepWizard from "react-step-wizard";
import { Container, Spinner } from "react-bootstrap";

import { Steps, getStatefulStep } from "@utils";
import { UseToursResult } from "@utils/useTours";
import { IVerifyStatus } from "@redux/slices/verifyStatus";
import { Email, Code, Tours, States, Disclosure, Tips, Done } from "@components";

type WizardProps = {
  state: IVerifyStatus;
  tourData?: UseToursResult;
  tourDataValidating: boolean;
};

export const Wizard: React.FC<WizardProps> = (props) => {
  return (
    <Container className="my-5 flex-grow-1">
      {props.tourDataValidating && props.state.hasTours ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <StepWizard
          isLazyMount={true}
          isHashEnabled={true}
          initialStep={getStatefulStep(props.state, props.tourData)}>
          <Email state={props.state} hashKey={Steps.email} />
          <Code state={props.state} hashKey={Steps.code} />
          <Tours
            state={props.state}
            hashKey={Steps.tours}
            tourData={props.tourData}
            tourDataValidating={props.tourDataValidating}
          />
          <States state={props.state} hashKey={Steps.states} />
          <Disclosure state={props.state} hashKey={Steps.disclosure} />
          <Tips state={props.state} hashKey={Steps.tips} />
          <Done
            state={props.state}
            hashKey={Steps.complete}
            tourData={props.tourData}
            tourDataValidating={props.tourDataValidating}
          />
        </StepWizard>
      )}
    </Container>
  );
};
