import React, { RefObject, useState } from "react";
import { Modal } from "react-bootstrap";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import { Button } from "@components";
import { ModalProps } from "@utils";

export const BioPolicy: React.FC<ModalProps> = (props) => {
  const [unread, setUnread] = useState<boolean>(true);
  const scrollRef: RefObject<HTMLDivElement> = useBottomScrollListener(() => {
    setUnread(false);
  });

  return (
    <Modal
      centered
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-fill"
      keyboard={props.oneClickLegal}
      backdrop={props.oneClickLegal ? true : "static"}>
      <Modal.Header style={{ fontWeight: 700 }} closeButton={props.oneClickLegal}>
        Irvine Company Biometric Privacy Policy
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", maxHeight: 720 }} ref={scrollRef}>
        <p>
          Effective Date: May 10, 2021
          <br />
          Last Updated: May 6, 2022
        </p>
        <p>
          This Biometric Privacy Policy describes how The Irvine Company LLC and its subsidiaries
          and affiliates (collectively, “Irvine”, “we”, “us”, or “our”) collect use, store, and
          share your biometric data when you access and use our identification verification process
          (“Process”).
        </p>
        <p>
          <b>Jumio</b>
        </p>
        <p>
          Please note, our Process is powered by Jumio, a service provider to Irvine. In addition to
          this Biometric Privacy Policy, Jumio’s Privacy Policy for Online Services may apply to
          additional types of information collected by Jumio during the Process such as GPS
          coordinates or IP Address, and controlled by Jumio. To learn more about Jumio’s privacy
          practices, please see Jumio’s Privacy Policy for Online Services.
        </p>
        <p>
          <b>Changes</b>
        </p>
        <p>
          We reserve the right to modify this Biometric Privacy Policy from time to time, which may
          be posted to this page. If required by law, we also may provide notification via another
          method reasonably likely to reach you, such as via e-mail (if you have an account where we
          have your contact information). Any modifications to this Biometric Privacy Policy will be
          effective upon our posting of the modifications and/or upon implementation of new changes
          to the Process.
        </p>
        <p>
          In all cases, your continued use of the Process after the posting of any modifications
          indicates you have read and understood our then-current Biometric Privacy Policy.
        </p>
        <p>
          <b>Acknowledgment</b>
        </p>
        <p>
          Please read this Biometric Privacy Policy carefully. The practices described herein are
          subject to the laws in the places in which we operate.
        </p>
        <p>
          BY ACCESSING AND/OR USING OUR PROCESS, YOU REPRESENT YOU HAVE READ AND UNDERSTOOD THIS
          BIOMETRIC PRIVACY POLICY.
        </p>
        <p>
          <b>What Is Biometric Data?</b>
        </p>
        <p>
          Biometric data includes biometric identifiers and biometric information. Biometric
          identifiers include things like a scan of your face or facial geometry. Biometric
          identifiers do not include signatures, photographs, or related information.
        </p>
        <p>
          Biometric information is any information that is based on a biometric identifier used to
          identify an individual. For example, our Process collects a picture of your ID and a scan
          of your face. We then match that scan to your identification presented to verify your
          identity. The scan of your face would constitute a biometric identifier. The information
          on your ID we use to compare to the scan of your face would constitute biometric
          information.
        </p>
        <p>
          <b>Why Do We Collect Your Biometric Data?</b>
        </p>
        <p>
          We collect your biometric data when you use the Process to verify your identity, prevent
          fraud, and to ensure the safety of those who work and live at our properties.
        </p>
        <p>
          <b>What Do You Do With My Biometric Data After You Collect It?</b>
        </p>
        <p>
          We will only use your biometric data for the purpose of providing the Process, verifying
          your identity, maintaining the security of our physical properties, preventing fraud, and
          allowing you to tour our properties (whether in-person or virtual). We will not use your
          biometric data for any other purpose. Specifically, we will not sell, lease, trade, or
          otherwise profit from your biometric data. We will also not disclose or disseminate your
          biometric data to anyone other than our service provider, Jumio.
        </p>
        <p>
          Please note, Jumio, our service provider, may share your biometric data to facilitate the
          Process, including comparing your biometric information against anti-fraud databases. For
          more information about Jumio’s practices, please see{" "}
          <a
            href="https://www.jumio.com/legal-information/privacy-policy/jumio-corp-privacy-policy-for-online-services/"
            target="_blank"
            rel="noreferrer"
            className="link">
            Jumio’s Privacy Policy for Online Services
          </a>
          .
        </p>
        <p>
          <b>How Long Do You Keep My Biometric Data?</b>
        </p>
        <p>
          We and our service provider, Jumio, will delete your biometric identifier (i.e., your
          facial scan) within twenty-four (24) hours after your use of the Process and completion of
          verification. After completion of verification, we will only hold certain non-biometric
          information collected through the Process, such as an image of your ID, for up to thirty
          (30) calendar days, after which such information will be deleted. In any event your
          biometric information will be deleted and permanently destroyed the earlier of (i) when
          the initial purpose for collecting the information has been satisfied or (ii) within three
          (3) years after your last interaction with Irvine regarding this Process.
        </p>
        <p>
          <b>How Will You Secure My Biometric Data?</b>
        </p>
        <p>
          Although no system can guarantee the complete security of your biometric data, we take all
          commercially reasonable steps to ensure your biometric data is protected in alignment with
          all applicable laws and regulations, as appropriate to the sensitivity of your biometric
          data.
        </p>
        <p>
          <b>What Are My Rights?</b>
        </p>
        <p>
          Depending on where you live, you may have the ability to ask us to take certain action
          with regard to your biometric data. To learn more about your options, please visit
          Irvine’s{" "}
          <a
            href="https://www.irvinecompany.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="link">
            Privacy Policy
          </a>
          .
        </p>
        <p>
          <b>How Does Irvine Treat The Biometric Data Of Minors?</b>
        </p>
        <p>
          The Process is directed to persons 18 years of age or older. We do not collect or use the
          biometric data of persons under the age of 18.
        </p>
        <p>
          <b>How Can I Contact Irvine If I Have Questions?</b>
        </p>
        <p>
          Please direct any questions or comments about this Biometric Privacy Policy to{" "}
          <a href="mailto:privacy@irvinecompany.com" className="link">
            privacy@irvinecompany.com
          </a>
          . You may also write to us via post mail at:
        </p>
        <p>
          Irvine Company
          <br />
          Attn: Information
          <br />
          550 Newport Center Drive, P.O. Box 6370
          <br />
          Newport Beach, CA 92658-6370
        </p>
      </Modal.Body>
      {!props.oneClickLegal && (
        <Modal.Footer>
          <Button onClick={props.onAccept} disabled={unread} active={!unread} size="sm">
            I Agree and Continue
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
