import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <Container
      fluid
      style={{
        color: "#333231",
        backgroundColor: "rgba(0,0,0,.125)"
      }}>
      <Row noGutters>
        <Col className="text-left py-4 my-auto" lg={true}>
          Irvine Management Company, a licensed real estate broker DRE Lic. #02041810
          <br />
          &copy;{year} The Irvine Company LLC. All Rights Reserved.
        </Col>
        <Col className="text-right py-4">
          <Row sm={1}>
            <a
              href="https://www.irvinecompany.com/privacy-policy/"
              className="px-4 py-0"
              target="_blank"
              rel="noreferrer">
              Privacy Policy
            </a>
          </Row>
          <Row sm={1}>
            <a
              href="https://www.irvinecompany.com/terms-of-use/"
              className="px-4 py-0"
              target="_blank"
              rel="noreferrer">
              Terms &amp; Conditions
            </a>
          </Row>
          <Row sm={1}>
            <a
              href="https://www.irvinecompany.com/ccpa/"
              className="px-4 py-0"
              target="_blank"
              rel="noreferrer">
              CA Privacy Choices
            </a>
          </Row>
          <Row sm={1}>
            <a
              href="https://www.irvinecompany.com/copyright-information/"
              className="px-4 py-0"
              target="_blank"
              rel="noreferrer">
              Copyright &amp; Photo Restrictions
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
