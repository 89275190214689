import React from "react";
import { Container } from "react-bootstrap";

export const Main: React.FC = () => {
  return (
    <div className="text-center py-4" style={{ backgroundColor: "#1c2a33" }}>
      <Container>
        <h1 className="text-white">Tour ID Verification</h1>
      </Container>
    </div>
  );
};
